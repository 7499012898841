/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.fullscreen {
    --width:100% !important;
    --height:100% !important;
}

ion-icon {
    font-size: 25px;
}

ion-label, ion-title, ul, ion-icon {
  color: #420046;
  // color: hsla(260,11%,85%,1);
}

ion-card {
  background-color: #2bcc42;
  margin-inline: 0px;
}

.tab-selected {
  background-color: #cc86d1;
}

.grid-actions {
  border: 1px solid #420046; margin: 3px;
  background-color: #97e831;

  ion-col {
    cursor: pointer;
    ion-label {
      display: block;
    }
  }
}

ion-icon {
 pointer-events: none;
}

ion-title {
  padding-top: 12px;
  text-align: center !important;
}

ion-datetime {
  padding-right: 5px;
}

ionic-selectable-modal {
  ion-toolbar {
    ion-title {
      padding-top: 0px !important;
    }
  }
  ion-searchbar {
    input {
      -webkit-padding-end: 0px !important;
      padding-left: 50px !important;
    }
  }
}

ion-select {
  min-width: 190px;
  padding-left: 3px;
}

.close-datetime-btn {
  background-color: transparent;
}

ion-input, ionic-selectable, input, ion-select, ion-select-popover, .list-md, select, textarea, ion-datetime {
  background-color: #fff !important;
  border-radius: 5px !important;
}

/*
*/
ion-input, ionic-selectable, input, ion-select, select, ion-datetime {
  height: 30px;
}

input {
  padding-left: 5px !important;
}

ion-button {
  background-color: aquamarine;
  border-radius: 5px !important;
}